.background {
   min-height: 100vh;
   height: auto;
   background: repeating-linear-gradient(119deg, #87D4E2 0%, #00A199 100%);
   display: flex;
}

.login-form {
   display: inline-block;
   width: 500px;
   height: 700px;
   margin: auto;
   padding-inline: 50px;
   background: #FFFFFF 0% 0% no-repeat padding-box;
   box-shadow: 6px 6px 10px #00000038;
   border-radius: 10px;
   opacity: 1;
}

.login-logo {
   margin-top: 50px;
   text-align: center;
}

.input-area {
   margin-top: 60px;
}

.input-box {
   margin-top: 30px;
   position: relative;

   input:focus~label,
   input:valid~label {
      font-size: 16px;
      left: 5px;
      bottom: 40px;
      color: #bbb;
   }

   input:focus~span,
   input:valid~span {
      width: 100%;
   }
}

.input {
   width: 400px;
   height: 40px;
   color: #000000;
   font-size: 18px;
   font-family: 'Roboto';
   border: none;
   border-bottom: solid #dbdbdb 1px;
   opacity: 1;
   position: relative;
   background: none;
   z-index: 5;
}

.input-error {
   color: #ED1C24 !important;
}

input:focus {
   outline: none;
}

.input-label {
   position: absolute;
   left: 0px;
   font-size: 18px;
   letter-spacing: -0.45px;
   color: #767676;
   font-family: 'Noto Sans KR';
   bottom: 10px;
   transition: all .2s;
}

.input-span {
   display: block;
   position: absolute;
   bottom: 0;
   left: 0%;
   background-color: #dbdbdb;
   width: 0;
   height: 2px;
   border-radius: 2px;
   transition: 0.5s;
}

.input-span-error {
   background-color: #ED1C24 !important;
}

.additional-area {
   margin-top: 5px;
   height: 90px;
}

.error-ment {
   margin-bottom: 5px;;
   text-align: left;
   font-size: 13px;
   font-family: 'Noto Sans KR';
   letter-spacing: -0.33px;
   color: #ED1C24;
   opacity: 1;
}

.check-box {
   margin-top: 15px;
   color: #000000;
   font-size: 15px;
   font-family: 'Noto Sans KR';
   cursor: pointer;
   display: inline-flex;
}

.login-button {
   width: 400px;
   height: 50px;
   background: #00A199 0% 0% no-repeat padding-box;
   border: none;
   border-radius: 5px;
   font-size: 18px;
   font-family: 'Noto Sans KR';
   letter-spacing: -0.45px;
   color: #FFFFFF;
   opacity: 1;
   cursor: pointer;
}

.find-area {
   margin-top: 20px;
   margin-inline: 90px;
   display: flex;
}

.find-logo {
   margin-top: 15px;
   text-align: center;
}

.find-button {
   height: 24px;
   font-size: 16px;
   font-family: 'Noto Sans KR';
   letter-spacing: -0.4px;
   color: #767676;
   cursor: pointer;
}

.seperator {
   margin-top: 2px;
   margin-inline: 19.5px;
   width: 0px;
   height: 20px;
   border: 1px solid #767676;
   opacity: 1;
}

.ment-account {
   margin-top: 100px;
   margin-inline: auto;
   width: 125px;
   height: 24px;
   font-size: 16px;
   font-family: 'Noto Sans KR';
   letter-spacing: -0.4px;
   color: #000000;
   opacity: 1;
}

.join-button {
   margin-top: 10px;
   width: 400px;
   height: 50px;
   background-color: #fff;
   border: 1px solid #00A199;
   border-radius: 5px;
   font-size: 18px;
   font-family: 'Noto Sans KR';
   letter-spacing: -0.45px;
   color: #00A199;
   opacity: 1;
   cursor: pointer;
}

.find-content {
   height: 476px;
}

.find-form {
   display: inline-block;
   width: 1200px;
   height: 790px;
   margin: auto;
   padding-inline: 100px;
   text-align: center;
   background: #FFFFFF 0% 0% no-repeat padding-box;
   box-shadow: 6px 6px 10px #00000038;
   border-radius: 10px;
   opacity: 1;
   .ant-tabs-ink-bar {
      border-bottom: 2px solid #00A199;
   }

   .ant-tabs-tab {
      color: #767676 !important;
      font-size: 20px;
      font-family: 'Noto Sans KR';
   }

   .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #00A199 !important;
      font-size: 20px;
      font-family: 'Noto Sans KR';
   }

   .login-button {
      margin-top: 70px;
   }
}

.find-ment {
   height: 120px;
   padding-top: 34px;
   display: block;
   text-align: center;
   font-family: 'Noto Sans KR';
   b {
      font-size: 20px;
      color: #00A199;
   }
   p {
      margin-top: 3px;
      margin-inline: auto;
      text-align: center;
      width: 580px;
      white-space: normal;
      font-size: 16px;
      color: #767676;
   }
}

.input-find {
   position: relative;
   display: flex;
   font-family: 'Noto Sans KR';
   font-size: 18px;
   margin-bottom: 10px;
   p {
      text-align: left;
      margin-top: 10px;
      margin-left: 250px;
      width: 110px;
      color: #767676;
   }
   input {
      width: 400px;
      height: 50px;
      color: #000000;
      border: none;
      border-bottom: solid #dbdbdb 1px;
      opacity: 1;
      position: relative;
      background: none;
      z-index: 5;
   }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

.find-seperator {
   position: absolute;
   width: 1000px;
   height: 0px;
   border: 1px solid #DBDBDB;
   opacity: 1;
}

.find-bottom-ment {
   margin-top: 30px;
   font-family: 'Noto Sans KR';
   font-size: 16px;
   letter-spacing: -0.4px;
   color: #767676;
}

.find-bottom-info {
   margin-top: 6px;
   font-family: 'Noto Sans KR';
   font-size: 16px;
   letter-spacing: -0.4px;
   color: #000000;
}

.complete-result {
   display: flex;
   text-align: center;
   justify-content: center;
   align-items: center;
   margin-top: 40px;
   width: 1000px;
   height: 127px;
   background: #FBFBFB 0% 0% no-repeat padding-box;
   opacity: 1;
   div {
      display: inline-flex;
      p {
         margin: 0 auto;
         letter-spacing: -0.45px;
         color: #767676;
      }
      span {
         margin: 0 auto;
         letter-spacing: -0.45px;
         color: #ED1C24;
      }
   }
}

.error-modal-content {
   height: 201px;
   text-align: center;
   img {
      width: 46px;
      height: 44px;
      margin-top: 33px;
   }
   p {
      margin-top: 10px;
      margin-bottom: 7px;
      font-family: 'Noto Sans KR';
      letter-spacing: -0.5px;
      font-size: 20px;
   }
   div p {
      margin-top: 0px;
      white-space: pre-wrap;
      word-break: break-all;
      color: #767676;
      font-family: 'Noto Sans KR';
      letter-spacing: -0.38px;
      font-size: 15px;
   }
}

.error-modal-footer {
   height: 129px;
   text-align: center;
   background-color: #F1F1F1;
   p {
      padding-top: 30px;
      margin-bottom: 10px;
      white-space: pre-wrap;
      color: #767676;
      font-family: 'Noto Sans KR';
      letter-spacing: -0.33px;
      font-size: 13px;
   }
   div p {
      padding-top: 0px;
      white-space: pre-wrap;
      word-break: break-all;
      color: #000000;
      font-family: 'Noto Sans KR';
      letter-spacing: -0.38px;
      font-size: 15px;
   }
}

.register-form {
   display: inline-block;
   width: 1200px;
   margin: auto;
   padding-inline: 50px;
   background: #FFFFFF 0% 0% no-repeat padding-box;
   box-shadow: 6px 6px 10px #00000038;
   border-radius: 10px;
   opacity: 1;
}

.register-logo {
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 15px;
   text-align: center;
   img {
      width: 190px;
      height: 50px;
   }
   p {
      margin-left: 10px;
      margin-bottom: 2px;
      font-family: 'Noto Sans KR';
      font-weight: 500;
      font-size: 25px;
      letter-spacing: -0.63px;
      color: #006D67;
      opacity: 1;
   }
}

.register-center {
   display: flex;
   align-items: center;
   justify-content: center;
}

.register-steps-background {
   margin-top: 30px;
   height: 100px;
}

.register-step-circle {
   width: 15px;
   height: 15px;
   border: 2px solid #DBDBDB;
   border-radius: 50%;
   opacity: 1;
}

.register-step-line {
   width: 203px;
   height: 0px;
   margin-inline: 16px;
   border: 1px solid #DBDBDB;
   opacity: 1;
}

.register-step-info {
   display: block;
   margin-inline: 95.5px;
   text-align: center;
}

.register-step-name {
   margin-top: 10px;
   margin-bottom: 0px;
   font-family: 'Roboto';
   font-weight: 500;
   color: #00A199;
   font-size: 13px;
}

.register-step-detail {
   font-family: 'Noto Sans KR';
   font-weight: 500;
   color: #00A199;
   font-size: 16px;
}

.register-step-not-selected {
   color: #767676 !important
}

.register-ment {
   display: block;
   text-align: center;
}

.register-title {
   margin-bottom: 0px;
   font-family: 'Noto Sans KR';
   font-weight: 700;
   font-size: 20px;
   letter-spacing: -0.5px;
   color: #000000;
}

.register-ment {
   margin-top: 5px;
   margin-bottom: 0px;
   font-family: 'Noto Sans KR';
   font-weight: 400;
   font-size: 15px;
   letter-spacing: -0.38px;
   color: #767676;
}

.register-start-seperator {
   width: 900px;
   height: 0px;
   margin-top: 30px;
   border: 2px solid #AED6D4;
   opacity: 1;
}

.register-terms-background {
   width: 900px;
   height: 50px;
   display: flex;
   align-items: center;
   padding-left: 20px;
   opacity: 1;
}

.register-terms-all {
   background-color: #F5F9F9;
   font-family: 'Noto Sans KR';
   font-weight: 700;
   font-size: 16px;
}

.register-terms {
   font-family: 'Noto Sans KR';
   font-weight: 500;
   font-size: 15px;
   span {
      color: #FF0000;
   }
}

.register-end-seperator {
   width: 900px;
   height: 0px;
   border: 1px solid #DBDBDB;
   opacity: 1;
}

.register-button {
   width: 150px;
   height: 50px;
   margin-inline: auto;
   margin-top: 50px;
   margin-bottom: 55px;
   background: #00A199 0% 0% no-repeat padding-box;
   border: none;
   border-radius: 5px;
   font-size: 18px;
   font-family: 'Noto Sans KR';
   font-weight: 500;
   letter-spacing: -0.45px;
   color: #FFFFFF;
   opacity: 1;
   cursor: pointer;
}

.register-terms-content {
   width: 820px;
   max-height: 500px;
   overflow: auto;
   margin-left: 50px;
   white-space: pre-wrap;
}

.register-input {
   position: relative;
   display: flex;
   font-family: 'Noto Sans KR';
   font-size: 18px;
   margin-bottom: 10px;

   p {
      text-align: left;
      margin-top: 10px;
      letter-spacing: -0.45px;
      width: 133px;
      color: #767676;
   }

   input {
      width: 400px;
      height: 50px;
      color: #000000;
      letter-spacing: -0.45px;
      border: none;
      border-bottom: solid #dbdbdb 1px;
      opacity: 1;
      position: relative;
      background: none;
      z-index: 5;
   }

   input::placeholder {
      color: #DBDBDB;
   }
}

.register-error {
   margin-top: -15px;
   margin-left: 133px;
   font-family: 'Noto Sans KR';
   font-weight: 400;
   font-size: 13px;
   color: #ED1C24;
   letter-spacing: -0.33px;
}

.register-input-error {
   color: #ED1C24 !important;
   border-color: #ED1C24 !important;
}

.register-email-possible {
   color: #00A199 !important;
}

.register-code-button {
   width: 130px;
   height: 50px;
   margin-left: auto;
   background: #767676 0% 0% no-repeat padding-box;
   border: none;
   border-radius: 5px;
   font-size: 17px;
   font-family: 'Noto Sans KR';
   font-weight: 500;
   letter-spacing: -0.45px;
   color: #FFFFFF;
   opacity: 1;
   cursor: pointer;
}

.input-phone {
   display: flex;

   input {
      width: 120px !important;
      text-align: center;
   }

   p {
      width: 20px;
      margin-left: 0;
      text-align: center;
   }
}

.approval-center {
   height: 500px;
   display: block;
   text-align: center;
   
   img {
      margin-top: 100px;
   }
   p {
      margin-top: 10px;
      margin-bottom: 5px;
      font-family: 'Noto Sans KR';
      font-weight: 700;
      font-size: 25px;
      letter-spacing: -0.63px;
   }
   span {
      font-family: 'Noto Sans KR';
      font-weight: 400;
      font-size: 15px;
      letter-spacing: -0.38px;
      line-height: 20px;
      white-space: pre-wrap;
   }
}

.userInfo-form {
   display: inline-block;
   width: 500px;
   margin: auto;
   padding-inline: 50px;
   padding-bottom: 30px;
   background: #FFFFFF 0% 0% no-repeat padding-box;
   text-align: center;
   box-shadow: 6px 6px 10px #00000038;
   border-radius: 10px;
   opacity: 1;
}

.userInfo-title {
   margin-top: 30px;
   margin-bottom: 30px;
   text-align: center;
   color: #00A199;
   font-size: 30px;
   font-family: 'Noto Sans KR';
   font-weight: 700;
}

.userInfo-input {
   position: relative;
   display: flex;
   font-family: 'Noto Sans KR';
   font-size: 18px;
   margin-bottom: 10px;

   p {
      text-align: center;
      letter-spacing: -0.45px;
      width: 133px;
      color: #767676;
   }

   i {
      width: 400px;
      height: 50px;
      color: #000000;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      letter-spacing: -0.45px;
      border: none;
      opacity: 1;
      position: relative;
      background: none;
      z-index: 5;
   }

}

.userInfoUpdate-form {
   display: inline-block;
   width: 500px;
   margin: auto;
   padding-inline: 50px;
   padding-bottom: 30px;
   background: #FFFFFF 0% 0% no-repeat padding-box;
   text-align: center;
   box-shadow: 6px 6px 10px #00000038;
   border-radius: 10px;
   opacity: 1;
}

.userInfoUpdate-input {
   position: relative;
   display: flex;
   font-family: 'Noto Sans KR';
   font-size: 18px;
   margin-bottom: 10px;

   p {
      text-align: center;
      letter-spacing: -0.45px;
      width: 180px;
      color: #767676;
   }

   input {
      width: 400px;
      height: 50px;
      margin-top: -12px;
      padding-left: 10px;
      color: #000000;
      letter-spacing: -0.45px;
      border: none;
      border-bottom: solid #dbdbdb 1px;
      opacity: 1;
      position: relative;
      background: none;
      z-index: 5;
   }

}

.userInfo-input-phone {
   display: flex;

   input {
      width: 79px !important;
      padding-left: 0px !important;
      text-align: center;
   }

   p {
      width: 20px;
      margin-left: 0;
      text-align: center;
   }
}

.userInfoUpdate-error {
   text-align: left;
   margin-top: -15px;
   margin-left: 133px;
   font-family: 'Noto Sans KR';
   font-weight: 400;
   font-size: 13px;
   color: #ED1C24;
   letter-spacing: -0.33px;
}