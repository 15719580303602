.layout-page {
   height: 100%;

   &-header {
      padding: 0 !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 9;
      box-shadow: 0 4px 10px #dddddd;

      svg {
         width: 20px;
         height: 20px;
         cursor: pointer;
      }

      &-main {
         padding: 0 15px;
         flex: 1;
         display: flex;
         justify-content: space-between;
         align-items: center;
      }

      .logo {
         height: 64px;
         width: 200px;
         box-sizing: border-box;
         display: flex;
         justify-content: center;
         align-items: center;
         z-index: 9;

         img {
            width: 30px;
            height: 30px;
         }
      }
   }

   &-sider {
      box-sizing: border-box;
      margin-bottom: 10px;
   }

   &-content {
      display: flex;
      flex-direction: column;
      flex: 1;

      > :nth-child(1) .ant-tabs-bar {
         padding: 6px 0 0;
      }

      > :nth-child(2) {
         flex: auto;
         overflow: auto;
         padding: 6px;
         box-sizing: border-box;

         .innerText {
            padding: 24px;
            border-radius: 2px;
            display: block;
            line-height: 32px;
            font-size: 16px;
         }
      }
   }

   &-footer {
      text-align: center;
      padding: 14px 20px;
      font-size: 12px;
   }

   .actions {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      >* {
         margin-left: 30px;
         height: 100%;
         display: flex;
         align-items: center;

         .notice {
            display: block;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 22px;
            height: 22px;
            cursor: pointer;
         }
      }
   }

   .user-action {
      cursor: pointer;
   }

   .user-avator {
      margin-right: 8px;
      width: 40px;
      height: 40px;
   }
}

body[data-theme="dark"] {
   .layout-page-header {
      box-shadow: none;
   }
}

.layout-page-sider-menu {
   border-right: none !important;
}

.ant-menu-inline-collapsed {
   width: 79px !important;
}

.notice-description {
   font-size: 12px;

   &-datetime {
      margin-top: 4px;
      line-height: 1.5;
   }
}

.notice-title {
   display: flex;
   justify-content: space-between;
}

.tagsView-extra {
   height: 100%;
   width: 50px;
   cursor: pointer;
   display: block;
   line-height: 40px;
   text-align: center;
}

.themeSwitch {
   position: fixed;
   right: 32px;
   bottom: 102px;
   cursor: pointer;

   >span {
      display: block;
      text-align: center;
      background: #fff;
      border-radius: 50%;
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
      width: 44px;
      height: 44px;
      line-height: 44px;
      font-size: 22px;
      z-index: 10001;
   }
}

.theme-color-content {
   display: flex;

   .theme-color-block {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      color: #fff;
      font-weight: 700;
      text-align: center;
      border-radius: 2px;
      cursor: pointer;
      border-radius: 2px;

      &:last-child {
         margin-right: 0;
      }
   }
}

.icon {
   width: 1.5em;
   height: 1.5em;
}

.menuSliderBtn {
   position: absolute;
   right:-15px;
   z-index: 999;
   top: 50%;
   transform: translate(0%, -50%);
   -webkit-animation: blink 2s ease-in-out infinite alternate;
   -moz-animation: blink 2s ease-in-out infinite alternate;
   animation: blink 2s ease-in-out infinite alternate;
   cursor: pointer;
   width: 30px;
   height: 30px;
   border-radius: 15px;
   text-align: center;
   background-color: rgba(200,200,200,0.5);
   line-height: 30px;
}

@-webkit-keyframes blink {
   0% {
      opacity: 0;
   }

   20% {
      opacity: 1;
   }
}

@-moz-keyframes blink {
   0% {
      opacity: 0;
   }

   20% {
      opacity: 1;
   }
}

@keyframes blink {
   0% {
      opacity: 0;
   }

   20% {
      opacity: 1;
   }
}