.overview {
   .ant-card-body {
      padding: 20px 24px 8px !important;
   }

   >* {
      box-sizing: border-box;
   }

   &-header {
      position: relative;
      width: 100%;
      overflow: hidden;

      &-meta {
         height: 22px;
         font-size: 14px;
         line-height: 22px;
      }

      &-count {
         height: 38px;
         margin-top: 4px;
         margin-bottom: 0;
         overflow: hidden;
         font-size: 30px;
         line-height: 38px;
         white-space: nowrap;
         text-overflow: ellipsis;
         word-break: break-all;
      }

      &-action {
         position: absolute;
         top: 4px;
         right: 0;
         line-height: 1;
         cursor: pointer;
      }
   }

   &-body {
      height: 46px;
      margin-bottom: 12px;
      position: relative;
   }

   &-footer {
      margin-top: 8px;
      padding-top: 9px;
      border-top: 1px solid #292a2d;
   }
}

.trend {
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;

   &-item {
      display: inline-block;
      font-size: 14px;
      line-height: 22px;

      &:first-child {
         margin-right: 16px;
      }

      svg {
         vertical-align: middle;
      }

      >* {
         margin-right: 8px;

         &:nth-child(2) {
            color: rgba(0, 0, 0, 0.85);
         }
      }
   }
}

.field {
   font-size: 14px;
   line-height: 22px;

   // &-label {
   // }
   &-number {
      margin-left: 8px;
      color: rgba(0, 0, 0, 0.85);
   }
}

.salePercent {
   .ant-list-item {
      padding: 8px 12px !important;

      >* {
         margin: 0 4px;
      }
   }
}

.customTooltip {
   transition: visibility 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s, left 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s,
      top 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s;
   background-color: rgba(256, 2560, 256, 0.9);
   box-shadow: rgb(174, 174, 174) 0px 0px 10px;
   border-radius: 3px;
   color: rgb(87, 87, 87);
   font-size: 12px;
   line-height: 20px;
   padding: 10px 10px 6px;

   &-title {
      margin-bottom: 4px;
   }

   &-content {
      margin: 0px;
      list-style-type: none;
      padding: 0px;

      >li {
         margin-bottom: 4px;
      }
      &-space {
         display: flex;
         justify-content: space-between;
      }
   }
}

.dashboardRoot .ant-pagination {
   display: none;
}

.dashboardRoot .ant-table-body {
   overflow: hidden !important;
}

// 탭 
.ant-tabs-tab.ant-tabs-tab-with-remove {
   border-top-left-radius: 16px !important;
   border-top-right-radius: 16px !important;
   border: 1px solid #f0f0f0 !important;
}

.dashboardRoot .ant-card-head {
   border: none;
}

.noticeModal .ant-modal-body {
   padding-top: 0px;
}

.mainListTitle {
   font-size: 1.1em;
   margin-left: 3px;
   margin-right: 10px;
   margin-bottom: 5px;
}

.mainListTitle .textlink {
   font-size: 0.75em;
   padding-top: 7px;
}

.searchArea.nonPagination.bottom {
   margin-top: 20px;
   margin-bottom: 0px;
}

.searchArea.pagination.bottom {
   margin-top: -40px;
   margin-bottom: 0px;
}

// 공지등록 Input 
.ant-modal-root .noticeWriter .ant-input {
   border: 1px #eee solid !important;
}

// 공지팝업 search 버튼(각개 검색 버튼) 
.ant-modal-root .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
   border: none;
   border-top-right-radius: 8px;
   border-bottom-right-radius: 8px;
}

// 공지팝업 search 버튼(각개 검색 버튼)의 root
.ant-modal-root .ant-input-group-addon {
   border-top-right-radius: 8px;
   border-bottom-right-radius: 8px;
}

// 공지팝업 search Input
.ant-modal-root .ant-input {
   padding-top: 5px;
   padding-bottom: 5px;
}

.top-notice {
   background-color: white;
   width: 99%;
   height: 24px;
   border-radius: 8px;
   display: flex;
   align-items: center;
   cursor: pointer;

   &-title {
      width: 15%;
      text-align: center;
      font-family: 'Roboto';
      font-weight: 700;
      font-size: 14px;
   }

   &-rolling {
      display: block;
      overflow: hidden;
      width: 42%;
      height: 100%;
   }
   
   &-notice {
      position: relative;
      display: flex !important;
      width: 100%;
      height: 100%;
      font-family: 'Noto Sans KR';
      font-size: 14px;

      >.ellipsesTableContents {
         padding-left: 5%;
         width: 100%;
         white-space: break-spaces;
         overflow: hidden;
         text-overflow: ellipsis;
   
         &-date {
            margin-left: auto;
            width: 80px;
            white-space: nowrap;
            margin-inline: 5%;
         }
      }
   }

   >div:nth-child(n+2) {
      position: relative;
      margin-left: 5px;
      padding-left: 10px;
   }
   
   >div:nth-child(n+2)::after {
      position: absolute;
      left: 0;
      top: 5%;
      content: "";
      width: 1px;
      height: 90%;
      background-color: #ddd;
   }

}

.date-selector .picker-close {
   position: absolute;
   width: 0;
   height: 0;
   padding: 0;
   overflow: hidden;
   border: 0;
}

.date-selector .picker-open {
   overflow: hidden;
}