.codeCheckRoot {
   text-align: center;
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
}

.codeCheckTitle{
   margin-bottom: 3%;
}

.checker {
   color: black;
   border-radius: 15px;
   background: white;
   text-align: center;
   padding: 1%;
   width: 90%
}

.checker .ant-select-selector{
   border-radius: 8px !important;
}

@media screen and (min-width: 380px) {
   .checker {
      width: 80%;
   }
}

@media screen and (min-width: 500px) {
   .checker {
      width: 70%;
   }
}

@media screen and (min-width: 850px) {
   .checker {
      width: 60%;
   }

      .codeCheckTitle {
         margin-bottom: 1%;
      }
}

@media screen and (min-width: 1300px) {
   .checker {
      width: 40%;
   }
}