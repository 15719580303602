html,
body,
#root,
#root>* {
   height: 100%;
}

body {
   font-size: 14px;
}

a {
   text-decoration: underline;
   cursor: pointer;
}

#searchForm>.ant-row>.ant-row.ant-form-item {
   width: 100%;
   text-align: right;
   padding-right: 12px;

   .ant-col {
      flex: 1;
      max-width: 100%;
      width: 100%;

      .ant-btn:first-child {
         margin-right: 10px;
      }
   }
}

.app-loading-wrapper.ant-spin-spinning {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 10000;
   background-color: rgba(256, 256, 256, 0.5);
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

// 버튼 스타일 공통
#root .ant-btn {
   border-radius: 8px;
}

// 인풋박스 공통 스타일링
#root .ant-input {
   border-radius: 8px;
}

// 메뉴>페이지의 상단 여백 조정
#pageTabs {
   margin-bottom: 10px;
   padding-bottom: 0px !important;
   border-bottom: #e9e9e9 1px solid;
}

// 팝컨펌의 인풋박스 색상 조정
.adminPopConfirm input {
   background: #f1f1f1;
}

/*#region ====== API테이블 상단 서치바 ======*/
.searchArea {
   display: flex;
   margin-bottom: 20px;
   text-align: center;
}

.selectBoxArea .ant-select.ant-select-single.ant-select-show-arrow .ant-select-selector {
   border-top-left-radius: 8px !important;
   border-bottom-left-radius: 8px !important;
   border-width: 0px;
}

#root .selectBoxArea .ant-btn {
   border-top-left-radius: 0px;
   border-bottom-left-radius: 0px;
   border-top-right-radius: 8px;
   border-bottom-right-radius: 8px;
   border-width: 0px;
}

#root .selectBoxArea .ant-input {
   border-width: 0px;
   height: 32px;
}

#root .selectBoxArea .ant-input-group-addon {
   border-top-right-radius: 8px;
   border-bottom-right-radius: 8px;
   border-width: 0px;
}

.selectBoxArea {
   padding-right: 20px;
   border-width: 0px;
   display: inline-flex;
}

.searchBoxInTh {
   display: flex;
}

.searchArea .searchBtn {
   padding-left: 10px;
   border-width: 0px;
   display: inline-flex;
}

.selectBoxTitle {
   margin-top: 5px;
   padding-right: 7px;
   font-size: 1em;
   font-weight: normal;
}

// Input.Search Only
.selectBoxInput.ant-input-group-wrapper {
   width: 200px;
}

// Input.Search Only
.selectBoxArea .selectBoxInput.ant-input-group-wrapper.notShowSelect .ant-input {
   border-top-left-radius: 8px;
   border-bottom-left-radius: 8px;
}

// Input
.selectBoxArea .selectBoxInput.ant-input {
   width: 200px;
}

// Input
.selectBoxArea .notShowSelect.ant-input {
   border-radius: 8px;
}

// 검색 버튼하나만 보일때
.selectBoxArea .selectBoxInput.ant-input.justOneBtn {
   border-top-right-radius: 8px;
   border-bottom-right-radius: 8px;
}

.searchBtn button {
   // border: none;
}

.searchArea .ant-input-group.ant-input-group-compact {
   display: inline-flex;
}

.searchArea .ant-input-group.ant-input-group-compact .selectBoxTitle {
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
}

.searchTableHead {
   text-align: left;
}

.searchTableTail {
   text-align: right;
}

.searchTable {
   width: 98%;
   margin: auto;
}

.searchAreaInfoMessage {
   font-size: 0.8em;
   color: #888;
   text-align: left;
   width: 96%;
   margin: auto;
}

/*#endregion==========================*/

// 테이블 공통
.ant-table-wrapper .ant-table-thead>tr>th {
   background: #f9f9f9;
}

// 테이블 중앙정렬, 크기 조정, 테두리(상단만 적용됨. 하단은 필요한 경우 방법을 찾아봐야 함)
.ant-table-wrapper {
   margin: auto;
   width: 98%;
   overflow: hidden;
}

.ant-table.ant-table-middle {
   border-radius: 8px;
   overflow: hidden;
}

// 하단 페이지 좌우버튼
.ant-pagination li button {
   border-radius: 8px !important;
}

// 하단 페이지 중앙버튼
.ant-pagination li {
   border-radius: 8px !important;
}

// 페이지 셀렉터
.ant-pagination li .ant-pagination-options-size-changer .ant-select-selector {
   border-radius: 8px !important;
   overflow: hidden;
   display: none;
   border: 1px solid #d9d9d9;
}


/*#region ====== 테이블 스타일(Table 태그. BasePageRef에는 미적용 ======*/
// 테이블 간 구분선
.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
   background-color: rgba(0, 0, 0, 0.1);
}

.ant-table-content table .ant-table-thead th {
   padding: 16px 12px !important; //상하, 좌우
   text-align: center;
   font-size: 14px;
}

.callLog .ant-table-content table{
   table-layout: fixed !important;
}

// 테이블 헤더
.ant-table-thead>tr>th {
   background: #f5f5f5;
}

// 테이블 좌상단 헤더 radius
.ant-table-container table>thead>tr:first-child th:first-child {
   border-top-left-radius: 12px;
}

// 테이블 우상단 헤더 radius
.ant-table-container table>thead>tr:first-child th:last-child {
   border-top-right-radius: 12px;
}

// 테이블 좌우 border 설정(위'테이블 좌상단 헤더 radius'의 radius와 동일하게 할것)
.ant-table-container {
   border-top-left-radius: 12px;
   border-top-right-radius: 12px;
}

//위와 동일하며 테이블 상 border 설정
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table {
   border-radius: 12px;
}

.ant-table.ant-table-middle {
   // border-radius: 14px;
}

/*#endregion==========================*/





/*#region === 테이블 스타일(BasePageRef에만 적용. Table 태그에는 적용X) ===*/

// 테이블 헤더 높이(BasePageRef)
.ant-table-container {
   font-size: 13px;
}

// 테이블 헤더 (BasePageRef)
.ant-table-header table .ant-table-thead th {
   padding: 20px 15px !important; //상하, 좌우
   text-align: center;
   font-size: 15px;
}

// 테이블 바디
.ant-table-tbody .ant-table-row td {
   padding: 20px 15px !important; //상하, 좌우
}

.ellipsesTableContents {
   overflow: hidden;
   text-overflow: ellipsis;

   word-wrap: break-word;
   font-size: 1em;
   display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
}

/*#endregion==========================*/


/*#region === 모달 공통 스타일링 === */
.ant-modal-root .ant-modal-content {
   border-radius: 6px;
   overflow: hidden;
}

// 버튼 스타일 공통
.ant-modal-root .ant-btn {
   border-radius: 6px;
}

// 인풋박스 공통 스타일링
.ant-modal-root .ant-input {
   border-radius: 6px;
   border: none !important;
}

.ant-modal-root .ant-select:not(.ant-select-customize-input) .ant-select-selector {
   border: none;
   border-radius: 6px;
}

.ant-modal-content .ant-modal-body {
   background-color: #F0F2F5;
}

.ant-modal-content .ant-modal-header {
   background-color: #F0F2F5;
}

.ant-modal-content .ant-modal-footer {
   background-color: #F0F2F5;
}

/*#endregion*/


.textlink:hover {
   cursor: pointer;
}

.textlink.colored {
   color: #888;
}

.listMoreIcon {
   width: 24px;
   font-size: 1.5em;
   text-align: center;
}

pre {
   font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}

.scrollBar {
   width: 100%;
   height: 100%;
   overflow-y: auto;
}

.scrollBar::-webkit-scrollbar {
   width: 8px;
}

.scrollBar::-webkit-scrollbar-thumb {
   height: 10%;
   background: #77889955;
   border-radius: 10px;
}

.scrollBar::-webkit-scrollbar-track {
   background: rgba(33, 122, 244, .05);
   border-radius: 10px;
}

// 검색창 라디우스 없애기
.noneRadius .ant-input {
   padding-top: 5px;
   padding-bottom: 5px;
   border-radius: 0px !important;
}